.navbar {
  z-index: 40;
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  width: 100%;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.navbar--fixed {
  position: fixed;
}

.navbar__container {
  width: 100%;
}

@media (width >= 640px) {
  .navbar__container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .navbar__container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .navbar__container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .navbar__container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .navbar__container {
    max-width: 1536px;
  }
}

.navbar__container {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 640px) {
  .navbar__container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 1024px) {
  .navbar__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.navbar__content {
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  display: flex;
}

.navbar__logo {
  transform: scale(.42);
}

.navbar__logo-link {
  width: 5rem;
  display: block;
}

.navbar__logo-image {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  width: 100%;
  height: auto;
}

.navbar__menu {
  display: none;
}

@media (width >= 768px) {
  .navbar__menu {
    display: block;
  }
}

.navbar__links {
  align-items: center;
  display: flex;
}

.navbar__links > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.navbar__links {
  align-items: baseline;
  margin-left: 2.5rem;
}

.navbar__link {
  border-radius: .375rem;
  padding: .5rem .75rem;
}

.navbar__link:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.navbar__actions {
  align-items: center;
  display: none;
}

.navbar__actions > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

@media (width >= 768px) {
  .navbar__actions {
    display: flex;
  }

  .navbar__mobile-menu {
    display: none;
  }
}

.navbar__mobile-button {
  width: 1.5rem;
  height: 1.5rem;
}

.nav-link.active {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

.nav-link:hover {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

.navbar__mobile-overlay {
  z-index: 50;
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  touch-action: none;
  backface-visibility: hidden;
  background-color: #0f172af2;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  transform: translate3d(0, 0, 0);
}

.navbar__mobile-link {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-family: Merriweather, serif;
  font-size: 1.5rem;
  line-height: 2rem;
}

.navbar__mobile-link:hover {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

.navbar__mobile-link {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.navbar__mobile-actions {
  align-items: center;
  margin-top: 3rem;
  display: flex;
}

.navbar__mobile-actions > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.navbar__mobile-close {
  border-radius: 9999px;
  padding: .5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.navbar__mobile-close:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.navbar__mobile-close {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.navbar__mobile-close-icon {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  width: 2rem;
  height: 2rem;
}

.no-scroll {
  overflow: hidden;
}

@supports (-webkit-touch-callout: none) {
  .navbar__mobile-overlay {
    height: -webkit-fill-available;
    position: fixed;
    inset: 0;
  }
}

.navbar__mobile-links {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.navbar__mobile-links > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.navbar__mobile-links {
  --tw-translate-y: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hero {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  padding-top: 4rem;
  overflow: hidden;
}

.hero__background {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  position: relative;
}

.hero__background-image {
  position: absolute;
  inset: 0;
}

.hero__image {
  -o-object-fit: cover;
  object-fit: cover;
  opacity: .2;
  width: 100%;
  height: 100%;
}

.hero__content {
  width: 100%;
}

@media (width >= 640px) {
  .hero__content {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .hero__content {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .hero__content {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .hero__content {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .hero__content {
    max-width: 1536px;
  }
}

.hero__content {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 640px) {
  .hero__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 1024px) {
  .hero__content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.hero__content {
  padding-top: 4rem;
  position: relative;
}

.hero__text {
  text-align: center;
}

.hero__title {
  color: #fff;
  margin-top: 4rem;
  margin-bottom: 2.5rem;
  font-family: Merriweather, serif;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
}

@media (width >= 768px) {
  .hero__title {
    font-size: 3rem;
    line-height: 1;
  }
}

.hero__subtitle {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
  margin-bottom: 2rem;
  font-family: Poppins, sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@media (width >= 768px) {
  .hero__subtitle {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.hero__buttons {
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: flex;
}

.hero__features {
  margin-top: 3.5rem;
}

.hero__features-grid {
  padding: 3rem 2rem;
  position: relative;
}

.hero__features-row {
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  display: flex;
}

.hero__feature {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-backdrop-blur: blur(12px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  background-color: #4755691a;
  border-width: 1px;
  border-color: #64748b33;
  border-radius: .5rem;
  padding: 1.5rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: relative;
}

.hero__feature:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hero__feature {
  flex: 1;
  width: 100%;
  min-width: 18rem;
  max-width: 32rem;
}

.hero__feature-header {
  align-items: center;
  gap: 1rem;
  display: flex;
}

@media (width >= 768px) {
  .hero__feature-header {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    opacity: 1;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }
}

.hero__feature-icon {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.hero__feature-title {
  font-family: Poppins, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.hero__feature-description {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  padding-bottom: 1.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: Poppins, sans-serif;
}

@media (width >= 768px) {
  .hero__feature-description {
    --tw-bg-opacity: 1;
    background-color: rgb(51 65 85 / var(--tw-bg-opacity));
  }
}

.hero__feature-description {
  margin-top: 1rem;
}

@media (width >= 768px) {
  .hero__feature-description {
    opacity: 0;
    --tw-translate-y: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    margin-top: 0;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    position: absolute;
    top: 1.5rem;
    left: 0;
    right: 0;
  }

  .group:hover .hero__feature-header {
    --tw-translate-y: -1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    opacity: 0;
  }

  .group:hover .hero__feature-description {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    opacity: 1;
  }
}

.products {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
  display: grid;
}

@media (width >= 768px) {
  .products {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 1024px) {
  .products {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.product-card {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
}

.product-card:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.product-card__image-container {
  height: 12rem;
  margin: 0;
  position: relative;
}

.product-card__image {
  -o-object-fit: cover;
  object-fit: cover;
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  width: 100%;
  height: 100%;
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.group:hover .product-card__image {
  --tw-grayscale: grayscale(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.product-card__image-overlay {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  opacity: .6;
  mix-blend-mode: hard-light;
  transition-property: opacity;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  inset: 0;
}

.group:hover .product-card__image-overlay {
  opacity: 0;
}

.product-card__content {
  padding: 1.5rem;
}

.product-card__title {
  color: #444;
  margin-bottom: .5rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.product-card__description {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.product-card__footer {
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  display: flex;
}

.product-card__price {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
  margin: 0;
  font-weight: 600;
}

.product-card__button {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
  align-items: center;
  gap: .5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.product-card__button:hover {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}

.product-card__button-icon {
  width: 1rem;
  height: 1rem;
}

.sr-only {
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.about {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #0f172af2;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (width >= 768px) {
  .about {
    margin-top: 7rem;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}

.about__content {
  grid-template-columns: auto 1fr;
  gap: 2rem;
  display: grid;
}

.about__text {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
  max-width: 42rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.about__logo {
  width: 100%;
  max-width: 8rem;
}

@media (width >= 768px) {
  .about__logo {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
}

.about__logo {
  grid-row: span 2;
}

.faq {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.faq > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.faq__title {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.faq__list {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.25rem;
  display: grid;
}

@media (width >= 768px) {
  .faq__list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.faq__item {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  padding: 2rem;
}

.faq__question {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
  margin-bottom: .5rem;
  font-family: Merriweather, serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.faq__answer {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  padding-top: .75rem;
  padding-left: 1.5rem;
  font-family: Poppins, sans-serif;
}

@media (width >= 768px) {
  .faq__answer {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.contact {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.contact__title {
  text-align: center;
  margin-bottom: 1.5rem;
  font-family: Merriweather, serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.contact__grid {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 3rem;
  display: grid;
}

@media (width >= 768px) {
  .contact__grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.contact__info > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.contact__info-title {
  margin-bottom: 1rem;
  font-family: Merriweather, serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.contact__info-item {
  align-items: center;
  display: flex;
}

.contact__info-item > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.contact-form > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.contact-form__input {
  border-radius: .25rem;
  width: 100%;
}

.contact-form__input:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.contact-form__input {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
  padding: .5rem 1rem;
}

.contact-form__input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(244 63 94 / var(--tw-ring-opacity));
}

.contact-form__submit {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

.contact-form__submit:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}

.footer {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.footer__container {
  width: 100%;
}

@media (width >= 640px) {
  .footer__container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .footer__container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .footer__container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .footer__container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .footer__container {
    max-width: 1536px;
  }
}

.footer__container {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 640px) {
  .footer__container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 1024px) {
  .footer__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.footer__container {
  text-align: center;
}

.search {
  max-width: 36rem;
  margin: 3rem auto;
  position: relative;
}

.search__input {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-radius: .5rem;
  width: 100%;
  padding: .75rem 1rem .75rem 2.5rem;
}

.search__input:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
  border-color: #0000;
  outline: 2px solid #0000;
}

.search__icon {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 50%;
  left: .5rem;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  scroll-behavior: smooth;
  font-family: Poppins, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Merriweather, serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.layout, .main {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  min-height: 100vh;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.container {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (width >= 640px) {
  .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 1024px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.nav-icon {
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
}

.nav-icon:hover {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.feature-icon {
  width: 2rem;
  height: 2rem;
}

.button {
  border-width: 2px;
  border-color: #0000;
  border-radius: .5rem;
  padding: .75rem 2rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.primary {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.primary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(225 29 72 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}

.secondary {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background-color: #0000;
  border-color: #e2e8f080;
}

.secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visible {
  visibility: visible;
}

.ml-60 {
  margin-left: 15rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.text-slate-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.section-title {
  color: #444;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

@media (width >= 768px) {
  .md\:hidden {
    display: none;
  }

  .md\:w-auto {
    width: auto;
  }
}
/*# sourceMappingURL=index.d4f7fd75.css.map */
